import React, { useContext, useState } from 'react';
import FileThumbBlock from './FileThumbBlock';
import FileDialogue from './FileDialogue';
import { Box, Typography } from '@mui/material';
import { FileBlockContext } from './FileBlockContext';



export default function FileThumbWithDialogue(props){

    const [showDialogue,setShowDialogue] = useState(false);
    const context = useContext(FileBlockContext);
    const {
        doc:{
            docName='',
        }
    } = context;
    

    return (
        <>
            <Box className="thumbBlock" >
                <FileThumbBlock onClick={()=>{setShowDialogue(true)}}/>
                <Typography className="blockLabel" > {docName}</Typography>
            </Box>
            {showDialogue &&
                <FileDialogue  showDialogue={showDialogue} setShowDialogue={setShowDialogue}/>
            }
        </>
    );

}