import { Box, Button, Divider, IconButton, Paper, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BrandContext } from "../../contexts/BrandContext";
import { getDefaultMatterName } from "../ReportViews/Matters/MatterUtilities";
import { es } from "date-fns/locale";
import { formatAddressToString, formatAddressToStringShort } from "../UniversalComponents";
import AddRelatedTransactionDialogue from "./AddRelatedTransactionDialogue.js";
import DeleteIcon from '@mui/icons-material/Delete';
import axios from '../../utils/axios';
import { cWarn } from "../CustomLogger.js";

export default function RelatedTransactions(props){

    const {
        context
    } = props;

    const brandContext = useContext(BrandContext);

    const {
        textContent:{
            relatedTransaction='',
            addRelatedTransaction=''
        }
    } = brandContext;

    const {
        relatedMatters
    } = context;
    
    const navigate = useNavigate();
    const [showAddRelation,setShowAddRelation] = useState(false);
    

    return (
        <Box className="relatedMattersContainerFullOuter">
            <Box
                sx={{display: 'flex', flexDirection: 'column', height:'100%'}}
            >
                <Box className="resultsContainer">
                    {relatedMatters.map((matter,index)=>(
                        <RelatedTransResult key={index} defaultExpanded={index === 0} matter={matter} context={context} />
                    ))}
                </Box>
                <Button className="roundButton smallButton blueButton" onClick={()=>{setShowAddRelation(true)}} sx={{ mt: "auto" }}>
                    {addRelatedTransaction}
                </Button>
            </Box>
            {showAddRelation &&
                <AddRelatedTransactionDialogue showDialogue={showAddRelation} setShowDialogue={setShowAddRelation} context={context} />
            }
        </Box>
    );

}

const removeRelation = (id,context) => {

    const {
        details:{
            id:matterId
        },
        loadData,
    } = context;

    const apiPath = `${process.env.REACT_APP_API_URL}/removerelatedmatter/${matterId}`;
    axios.post(apiPath,{
        matter:id,
    },{
        timeout: 300000,
        withCredentials:true
    }).then((res) => {
        const {
            data:{
                success=false
            }
        } = res;
        if(success){
            loadData();
        }
        else{
            cWarn('There was a problem removing the relation');
        }
        
    }).catch(err => {
        cWarn(err); 
    });
}


const RelatedTransResult = (props) => {
    const {
        matter:{
            matterData=null,
            estateAgencyData=null,
            matterType:{
                id:matterTypeId,
                matterDepartment:{
                    id:departmentId
                }
            }
        },
    } = props;
    if(departmentId === 1){
        if(estateAgencyData){
            return <EAResult {...props} />
        }
        return <ConveyancingResult {...props} />
    }
    if(departmentId === 3){
        return <PCResult {...props} />
    }

}


const BaseResult = (props) => {
    const {
        id:relationId,
        linkPath='',
        context,
        title='',
        defaultExpanded=false
    } = props;
    const {
        textContent:{
            removeRelation:removeButtonText=''
        }
    } = useContext(BrandContext);
    
    const [expanded,setExpanded] = useState(defaultExpanded);
    return (
        <Paper className="relatedMattersContainer" >
            <Box>
                <Typography className="relatedMattersInnerTitle" onClick={()=>{setExpanded(!expanded)}}>{title}</Typography>
            </Box>
            {expanded &&
                <>
                    <Box className="relatedMattersInnerContainer"  onClick={() =>{
                        window.open(linkPath, '_blank');
                    }}>
                        {props.children}
                    </Box>
                    <Divider/>
                    <Box className="resultsTools">
                        <Typography 
                            class="removeLink"
                            variant="body2" 
                            onClick={(e) => {
                                e.stopPropagation(); 
                                removeRelation(relationId, context);
                            }}
                        >
                            {removeButtonText}
                        </Typography>
                    </Box>
                </>
            }
        </Paper>
    );
}


const EAResult = (props) => {
    
    const {textContent} = useContext(BrandContext);

    const {
        matter:{
            id,
            case:mainCase=null,
            matterType:{
                name:matterTypeName
            },
            estateAgencyData:{
                valuationType=null,
            },
            events=[],
            matterUserRoles:userRoles=[]
        }
    } = props;
    
    let valuationTypeString='';
    let valuationTypeId = null;
    if(valuationType){
        ({textKey:valuationTypeString,id:valuationTypeId} = valuationType);
    }

    const {
        textContent:{
            relatedSaleLabel='',
            valuationDateTimeLabel='',
            valuerLabel=''
        }
    } = useContext(BrandContext)

    let valuationDateFormatted = '';
    if(events.length > 0){
        const {startTime:valuationDate} = events[0];
        const dateObj = new Date(valuationDate);
        const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
        const timeOptions = { hour: 'numeric', minute: 'numeric' };

        const formattedDate = new Intl.DateTimeFormat('en-GB', dateOptions).format(dateObj);
        const formattedTime = new Intl.DateTimeFormat('en-GB', timeOptions).format(dateObj);

        valuationDateFormatted = `${formattedDate} at ${formattedTime}`;
    }
    let valuer = null;
    let valuerName = null;
    if(userRoles){
        if(userRoles.length > 0){
            for(let i=0; i<userRoles.length; i+=1){
                const {
                    roleType:{
                        id:roleTypeId=0
                    }
                } = userRoles[i];
                if(roleTypeId === 8){
                    valuer = userRoles[i];
                    break;
                }
            }
        }
    }
    if(valuer){
        ({
            bdUser:{
                fullName:valuerName
            }
        } = valuer);
    }



    return (
        <BaseResult id={id} title={relatedSaleLabel} linkPath={`https://ea.doobray.com/valuation/${id}`} {...props}>
            <Box>
                <Typography className="rTransStandard">
                    {formatAddressToString(mainCase)}
                </Typography>
                {valuationDateFormatted && 
                    <Typography className="rTransStandard" sx={{marginTop:'10px'}}>
                        <Typography className="boldLabel" component="span">{valuationDateTimeLabel}</Typography>
                        <br/>
                        <Typography className="boldLabel blueLabel" component="span">{valuationDateFormatted}</Typography>
                    </Typography>
                }
                <Box sx={{display:'flex', justifyContent:'center', paddingTop:'10px'}}>
                    <Button className="roundButton smallButton whiteButton" style={{marginBottom:0}}>
                        {textContent[valuationTypeString]}
                    </Button>
                </Box>
                {valuerName && 
                    <Typography className="rTransStandard" sx={{marginTop:'10px'}}>
                        {valuerLabel} <Typography className="boldLabel blueLabel" component="span">{valuerName}</Typography>
                    </Typography>
                }
            </Box>
        </BaseResult>
    );
}



const ConveyancingResult = (props) => {
    const {
        matter:{
            id,
            case:{
                entryDate=null,
                loanPapersIn,
                missivesConcluded
            },
            case:mainCase,
            matterType:{
                name:matterTypeName,
                id:matterTypeId
            }
        }
    } = props;

    let formattedDate = '';
    if(entryDate) {
        const date = new Date(parseInt(entryDate) * 1000); // Convert to milliseconds

        // Format the date as "07 Feb 2020"
        formattedDate = new Intl.DateTimeFormat('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        }).format(date);
    }

    const {
        textContent:{
            conveyancingLabel='',
            entryDateLabel='',
            missivesNotConcludedLabel='',
            missivesConcludedLabel='',
            loanPapersInLabel='',
            loanPapersNotInLabel=''
        }
    } = useContext(BrandContext)

    const title = `${conveyancingLabel} ${matterTypeName}`
    

    return (
        <BaseResult id={id} title={title} linkPath={`https://beta.doobray.com/transactions&fn=edit&trans=${id}`} {...props}>
            <Typography className="rTransStandard">
                {formatAddressToStringShort(mainCase)}
            </Typography>
            <Typography className="rTransStandard" sx={{marginTop:'10px'}}>
               <Typography component="span" className="boldLabel">{entryDateLabel}</Typography> {formattedDate}
            </Typography>
            <Box sx={{marginTop:'10px'}}>
                {missivesConcluded ?
                    <Button className="roundButton smallButton greenButton" style={{marginBottom:0}}>{missivesConcludedLabel}</Button>
                    :
                    <Button className="roundButton smallButton redButton" style={{marginBottom:0}}>{missivesNotConcludedLabel}</Button>
                }
            </Box>
            {matterTypeId === 2 &&
            <Box sx={{marginTop:'10px'}}>
                {loanPapersIn ?
                    <Button className="roundButton smallButton greenButton" style={{marginBottom:0}}>{loanPapersInLabel}</Button>
                    :
                    <Button className="roundButton smallButton redButton" style={{marginBottom:0}}>{loanPapersNotInLabel}</Button>
                }
            </Box>
            }
        </BaseResult>
    );
}

const PCResult = (props) => {
    const {
        matter:{
            id,
            matterData,
            matterUserRoles=[]
        },
        matter
    } = props;
                  
    
    let dueDate = '';
    let matterName = '';
    if(matterData){
        ({dueDate,matterName} = matterData);
    } 
    if(matterName === '' || matterName === null){
        matterName = getDefaultMatterName(matter);
    }
    let dueDateFormatted = '';
    if(dueDate){
        const dateObj = new Date(dueDate);
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        dueDateFormatted = new Intl.DateTimeFormat('en-GB', options).format(dateObj);
    }

    const getFirstCharacter = (inputString) => inputString.length > 0 ? inputString[0].toUpperCase() : '';

    const {
        textContent:{
            targetDate='',
        }
    } = useContext(BrandContext)

    return (
        
        <BaseResult id={id} title={matterName} linkPath={`https://pc.doobray.com/matter/${id}`} {...props}>    
            <Box>
                <Typography className="rTransStandard" sx={{marginTop:'10px'}}>
                    <Typography component="span" className="boldLabel">{targetDate}</Typography> {dueDateFormatted}
                </Typography>
            </Box>
        </BaseResult>
    );
}