import { withStyles } from '@mui/styles';
import {
    createTheme, responsiveFontSizes
} from '@mui/material/styles';



const FONT_PRIMARY = 'Poppins';
const PALETTE = {
        type: 'light',
        brandedPrimary: '#222546',
        brandedSecondary: '#1772DF',
        navBack:'#3A3B59',
        paleBlue: '#E5F4F9',
        greyLinkText: '#565656',
        lightGrey: '#F6F6F6',
        midGrey: '#E7E8E8',
        darkerGrey: '#D9D9D9',
        greyBackground: '#D7D7D7',
        greyText: '#707070',
        green: '#018E00',
        complete: '#9ACD32',
        red: '#9A0101',
        orange: '#EE8301',
        brightBlue: '#1772DF',
        boldBlue:'#2E72DF',
        white: '#FFFFFF',
        greyBorder:'#d9d9d9',
        primary: {
            main: '#222546'
        },
        secondary:{
            main: '#1772DF'
        }
    };
const theme = createTheme({
    palette: PALETTE,
    defaultFontFamily:FONT_PRIMARY,
    typography:{
        fontFamily: FONT_PRIMARY,
        fontWeightLight: 400,
        fontWeightRegular: 500,
        fontWeightMedium: 600,
        fontWeightBold: 700,
        h1:{
            fontWeight:600,
            fontFamily:FONT_PRIMARY,
            fontSize:'36px',
            lineHeight:1,
            letterSpacing:0,
            color:PALETTE.brandedPrimary
        },
        h2:{
            fontWeight:600,
            fontFamily:FONT_PRIMARY,
            fontSize:'30px',
            lineHeight:1,
            letterSpacing:0,
            color:PALETTE.brandedPrimary
        },
        h2Thin:{
            fontWeight:400,
            fontFamily:FONT_PRIMARY,
            fontSize:'28px',
            lineHeight:1,
            letterSpacing:0,
            color:PALETTE.brandedPrimary
        },
        h3Big:{
            fontWeight:600,
            fontFamily:FONT_PRIMARY,
            fontSize:'21px',
            lineHeight:1,
            letterSpacing:0,
            color:PALETTE.brandedPrimary
        },
        h3:{
            fontWeight:600,
            fontFamily:FONT_PRIMARY,
            fontSize:'18px',
            lineHeight:1,
            letterSpacing:0,
            color:PALETTE.brandedPrimary
        },
        h4:{
            fontWeight:600,
            fontFamily:FONT_PRIMARY,
            fontSize:'16px',
            lineHeight:1,
            letterSpacing:0,
            color:PALETTE.brandedPrimary
        },
        h5:{
            fontWeight:600,
            fontFamily:FONT_PRIMARY,
            fontSize:'16px',
            lineHeight:1,
            letterSpacing:0,
            color:PALETTE.brandedPrimary
        },
        h6:{
            fontWeight:600,
            fontFamily:FONT_PRIMARY,
            fontSize:'20px',
            lineHeight:1,
            letterSpacing:0,
            color:PALETTE.brandedPrimary
        },
        body1:{
            fontFamily:FONT_PRIMARY,
            fontSize:'14px',
            lineHeight:1.5,
            letterSpacing:0,
        },
        body1Bold:{
            fontFamily:FONT_PRIMARY,
            fontSize:'14px',
            fontWeight:600,
            lineHeight:1.5,
            letterSpacing:0,
        },
        resTitle:{
            fontSize:'18px'
        },
        resTitleBold:{
            fontSize:'18px',
            fontWeight:'bold'
        },
        resTitleItalic:{
            fontSize:'18px',
            fontWeight:'bold',
            fontStyle:'italic'
        },
        smallText:{
            fontFamily:FONT_PRIMARY,
            fontSize:'11px',
        }
      }
});

export const responsiveTheme = responsiveFontSizes(theme);

export const BrandedStyles = withStyles((theme) => ({
    '@global': {
        '& .fixedHeaderContainer':{
            height:150,
            '& .fixerBox':{
                position:'fixed',  
                left:0, 
                top:8, 
                width:'100%',
                zIndex:300, 
                height:140, 
                backgroundColor:'#FFFFFF' 
            }
        },
        '.standardSelect':{
            width:'100%'
        },
        '& .roundButton':{
            cursor:'pointer',
            borderRadius:'30px',
            backgroundColor:theme.palette.brandedPrimary,
            color:theme.palette.white,
            fontFamily: theme.defaultFontFamily,
            lineHeight:'40px',
            fontSize:'15px',
            fontWeight:600,
            paddingTop:'0px',
            paddingBottom:'0px',
            marginBottom:'20px',
            paddingLeft:20,
            paddingRight:20
        },
        '& .roundButton.smallButton':{
            borderRadius:'30px',
            backgroundColor:theme.palette.brandedPrimary,
            color:theme.palette.white,
            fontFamily: theme.defaultFontFamily,
            lineHeight:'30px',
            fontSize:'15px',
            fontWeight:600,
            paddingTop:'0px',
            paddingBottom:'0px',
            marginBottom:'20px',
            paddingLeft:20,
            paddingRight:20
        },
        '& .roundButton:hover':{
            backgroundColor:theme.palette.brandedPrimary,
        },
        '& .subtleLink':{
            cursor:'pointer'
        },
        '& .blueButton':{
            backgroundColor:theme.palette.brandedSecondary,
        },
        '& .blueButton:hover':{
            backgroundColor:theme.palette.brandedSecondary,
        },
        '& .roundButton.blueButton':{
            backgroundColor:theme.palette.brandedSecondary,
        },
        '& .roundButton.blueButton:hover':{
            backgroundColor:theme.palette.brandedSecondary,
        },
        '& .roundButton.whiteButton':{
            backgroundColor:theme.palette.white,
            color:theme.palette.brandedPrimary,
            border:'1px solid',
            borderColor:theme.palette.brandedPrimary

        },
        '& .roundButton.whiteButton:hover':{
            backgroundColor:theme.palette.white,
            color:theme.palette.brandedPrimary
        },
        '& .roundButton.greenButton':{
            backgroundColor:theme.palette.white,
            color:theme.palette.green,
            border:'1px solid',
            borderColor:theme.palette.green

        },
        '& .roundButton.greenButton:hover':{
            backgroundColor:theme.palette.white,
            color:theme.palette.green
        },
        '& .roundButton.redButton':{
            backgroundColor:theme.palette.white,
            color:theme.palette.red,
            border:'1px solid',
            borderColor:theme.palette.red

        },
        '& .roundButton.redButton:hover':{
            backgroundColor:theme.palette.white,
            color:theme.palette.red
        },
        '.brandedDialogue':{
            '& .MuiDialogTitle-root':{
                backgroundColor:theme.palette.brandedPrimary,
                color:theme.palette.white,
                textAlign:'center'
            },
            '& .MuiDialogContent-root':{
                paddingTop:'20px'
            }
        },
        '.dialogeWithAutoType':{
            '& .MuiPaper-root':{
                overflowY: 'visible',
            }
        },
        '.login-box':{
            backgroundColor:theme.palette.brightBlue,
            '& .login-title':{
                fontFamily: theme.defaultFontFamily,
                fontSize:'30px',
                color:theme.palette.white,
            },
            '& .loginButton':{
                borderRadius:'30px',
                backgroundColor:theme.palette.brandedPrimary,
                color:theme.palette.white,
                fontFamily: theme.defaultFontFamily,
                lineHeight:'40px',
                fontSize:'15px',
                fontWeight:600,
                paddingTop:'0px',
                paddingBottom:'0px',
                minWidth:'330px',
                marginBottom:'20px',
                paddingLeft:20,
                paddingRight:20
            },
            '& .MuiButton-label':{
                justifyContent:'space-between',
            }
        },
        '.sortingOfficeDrop':{
            minHeight:'100px',
        },
        '.fileBlocksContainer':{
            display:'flex', 
            flexDirection:'row', 
            flexWrap:'wrap', 
            marginTop:2
        },
        '.thumbBlock':{
            width:'100px',
            height:'120px', 
            overflow:'hidden', 
            display:'flex', 
            flexDirection:'column', 
            alignItems:'center',
            '& .blockLabel':{
                fontSize:'12px', 
                cursor:'pointer', 
                textAlign:'center'
            }
        },

        '.thumbBlock.bordered':{
            width:'75px',
            height:'75px', 
            overflow:'hidden', 
            display:'flex',              
            alignItems:'center',
            border:'1px solid #ccc',
            '& .blockLabel':{
                fontSize:'12px', 
                cursor:'pointer', 
                textAlign:'center'
            }
        },


        '.darkWidgetContainer':{
            backgroundColor:theme.palette.lightGrey,
            padding:'20px',
            marginTop:'-20px',
            marginBottom:'-20px',
            marginLeft:'-20px',
            marginRight:'-20px',
        },
        '.digiFileContainer':{
            '& .messageContainerRow':{
                display:'flex',
                '& .messageHeader':{
                    display:'flex',
                    '& .emailDate':{
                        whiteSpace:'nowrap'
                    }
                },
                '& .messageContainer':{
                    marginBottom:'20px',
                    padding:'20px',
                    flexGrow:1,
                    marginRight:'30px',
                    backgroundColor:theme.palette.paleBlue,
                    '& .previewContainer':{
                        display:'flex',
                        flexDirection: 'row-reverse',
                        padding:'20px',
                        '& .toggleButton':{
                            cursor:'pointer'
                        },
                        '& .messagePreview':{
                            whiteSpace:'nowrap',
                            overflow:'hidden',
                            textOverflow:'ellipsis',
                        }
                    }
                },
                '& .incomingMessage':{
                    marginRight:0,
                    marginLeft:'30px',
                    backgroundColor:theme.palette.white
                }
            },
            
        },
        '.bluePaper':{
            backgroundColor:theme.palette.paleBlue,
            padding:'20px'
        },
        '.boxHeader':{
            backgroundColor:theme.palette.paleBlue,
            padding:20
        },
        '.blueBold':{
            fontWeight:600,
            color:theme.palette.brightBlue
        },
        '.miniWidget':{
            '& .miniWidgetHeader':{
                backgroundColor:theme.palette.paleBlue,
                '& .MuiCardHeader-title':{
                    fontSize:'18px',
                    fontWeight:600
                }
            },
            '& .innerMiniWidget':{
                padding:20
            },
            marginBottom:20
        },
        '.bigWidget':{
            '& .bigWidgetHeader':{
                backgroundColor:theme.palette.lightGrey,
                '& .MuiCardHeader-title':{
                    fontSize:'18px',
                    fontWeight:600
                }
            },
            '& .bigWidgetHeader.isOpen':{
                backgroundColor:theme.palette.brandedPrimary,
                color:theme.palette.white,
                '& .menuOpener':{
                    color:theme.palette.white
                }
            },
            '& .innerBigWidget':{
                padding:20
            },
            marginBottom:20
        },
        '.buttonList':{
            
        },
        '.detailsList':{
            '& .detailsListItem':{
                paddingLeft:0
            },
            '& .detailsListItem-enabled':{
                color:theme.palette.green,
                '& .MuiSvgIcon-root':{
                    color:theme.palette.green,
                }
            },
            '& .detailsListItem-disabled':{
                color:theme.palette.red,
                '& .MuiSvgIcon-root':{
                    color:theme.palette.red
                }
            }
        },
        '.hiddenResult':{
            display:'none'
        },
        '.hiddenResultVisible':{
            opacity:0.3
        },
        '.navDrawer':{
            '& .MuiDrawer-paper':{
                backgroundColor:theme.palette.brandedPrimary
            },
            '& .logoContainer':{
                marginTop:30,
                '& .logoHover':{
                    color:theme.palette.white,
                    fontFamily: theme.defaultFontFamily,
                    fontWeight:550,
                    fontSize:13,
                    textTransform:'uppercase'
                }
            },
            '& .linkTitle':{
                '& .MuiTypography-root':{
                    color:theme.palette.white,
                    fontFamily: theme.defaultFontFamily,
                    fontWeight:400,
                    fontSize:20
                }
            },
            '& .linkSub':{
                '& .MuiTypography-root':{
                    color:theme.palette.white,
                    fontFamily: theme.defaultFontFamily,
                    fontWeight:550,
                    fontSize:16,
                    paddingLeft:20
                }
                
            },
            '& .linkTitleActive':{
                backgroundColor:theme.palette.navBack,
                borderBottom:'4px solid',
                borderRadius:0,
                borderBottomColor:theme.palette.brandedSecondary,
            },
        },
        '.outlaysBreadownContainer':{
            marginTop:'20px', 
            '& .cardContainer':{
                '& .MuiCardHeader-title':{
                    textAlign:'center'
                },
                '& .fieldsContainer':{
                    padding:'20px'
                }
            }
        },
        '.clickableStop':{
            cursor:'pointer'
        },
        '.suggestedStop':{
            '& p':{
                fontWeight:'bold'  
            }
            
        },
        '.widgetTabs':{
            overflow:'visible !important',
            '& .MuiTabs-scroller':{
                overflow:'visible !important',
            },
            '& .buttonTab':{
                fontSize:'19px',
                fontWeight:'bold'
            },
            '& .MuiTabs-indicator':{
                backgroundColor:theme.palette.brightBlue,
                height:'4px',
                marginBottom:'-2px'
            }
        },
        '.selectedRoute':{
            '& .routeTitle':{
                fontWeight:'bold'  
            },
            '& .routeSubTitle':{
                fontWeight:'bold'  
            },
            '& .selectedStop':{
                backgroundColor:theme.palette.grey[500_32],
            }
        },
        '& .mainNavSubMenu':{
            color:'#FF0000',
            '& .MuiPaper-root':{
                borderRadius:0,
                backgroundColor:theme.palette.lightGrey
            },
            '& .MuiMenuItem-root':{
                fontFamily:theme.defaultFontFamily,
                fontSize:'15px',
                lineHeight:'36px',
                fontWeight:400,
                color:theme.palette.greyText

            }
        },
        '& .headerProfileButtonMenu':{
            color:'#FF0000',
            '& .MuiPaper-root':{
                borderRadius:0,
                backgroundColor:theme.palette.lightGrey
            },
            '& .MuiMenuItem-root':{
                fontFamily:theme.defaultFontFamily,
                fontSize:'15px',
                lineHeight:'36px',
                fontWeight:550,
                color:theme.palette.greyText

            }
        },
        '& .inputHolder':{
            marginBottom:20
        },
        '.formCard':{
            backgroundColor:theme.palette.lightGrey,
            padding:50,
            '& .cardInput':{
                backgroundColor:theme.palette.white
            },
            '& .inputHolder':{
                marginBottom:20
            }
        },
        '.resultsCard':{
            backgroundColor:theme.palette.lightGrey,
            '& .cardSearch':{
                backgroundColor:theme.palette.white,
                '&::legend': {
                    fontStyle:'italic'
                  }
            },
            '& .cardSearch::placeholder':{
                fontStyle:'italic'
            },
            '& .filterButton':{
                borderRadius:'30px',
                backgroundColor:theme.palette.brandedPrimary,
                color:theme.palette.white,
                fontFamily: theme.defaultFontFamily,
                lineHeight:'40px',
                fontSize:'15px',
                fontWeight:600,
                paddingTop:'0px',
                paddingBottom:'0px',
                paddingLeft:20,
                paddingRight:20
            },
            '& .bigWideTable':{
                marginTop:30,
                '& .MuiTableCell-head':{
                    backgroundColor:theme.palette.brandedPrimary,
                    color:theme.palette.white,
                    fontFamily:FONT_PRIMARY,
                    fontSize:'15px',
                    fontWeight:600
                },
                '& .MuiTableCell-head:first-of-type':{
                    borderTopLeftRadius:'5px'
                },
                '& .MuiTableCell-head:last-of-type':{
                    borderTopRightRadius:'5px'
                },
                '& .MuiTableCell-body':{
                    backgroundColor:theme.palette.white,
                    fontSize:'12px',
                    fontWeight:600,
                    fontFamily:FONT_PRIMARY,
                    borderLeft:'1px solid rgba(244,244,244,1)'                
                },
                '& .MuiTablePagination-toolbar':{
                    color:theme.palette.brandedPrimary
                }
            }
        },
        '.bigNavBar':{
            paddingTop:'0px',
            paddingBottom:'0px',
            
            backgroundColor:theme.palette.brandedPrimary,
            '& .logoContainer':{
                marginRight:30,
                position:'relative',
                '& .logoHover':{
                    right:-15,
                    bottom:0,
                    position:'absolute',
                    color:theme.palette.brandedSecondary,
                    fontFamily: theme.defaultFontFamily,
                    fontWeight:550,
                    fontSize:13,
                    textTransform:'uppercase'
                }
            },
            '& .tBar':{
                paddingLeft:0,
                paddingRight:0,
                '& .linkTitle':{
                    marginLeft: '10px',
                    padding:'0 5px 0 5px',
                    fontSize:'16px',
                    lineHeight:'59px',
                    textDecoration: 'none',
                    color:theme.palette.white,
                    fontFamily: theme.defaultFontFamily,
                    fontWeight:600,
                    marginRight:10,
                    height:'100%',
                    textTransform:'none',
                    borderBottom:'4px solid',
                    borderBottomColor:theme.palette.brandedPrimary,
                    [theme.breakpoints.down("sm")]: {
                        display:'none'
                    }
                },
                
                '& .linkTitleActive':{
                    backgroundColor:theme.palette.navBack,
                    borderBottom:'4px solid',
                    borderBottomColor:theme.palette.brandedSecondary,
                },
                '& .signOut':{

                }
            }
        },
        '.pageHeader':{
            marginTop:80
        },
        '.cardResult':{
            marginBottom:'20px',
            '& .resultHeader':{
                display:'flex', 
                padding:'20px'
            },
            '& .arrowContainer':{
                display:'flex', 
                height:'100%', 
                flexDirection:'column', 
                alignContent: 'flex-end'
            }
        },
        '.imgResult':{
            position:'relative', 
            height:'90px',
            '& .thumbContainer':{
                position:'absolute', 
                height:'40px', 
                paddingTop:'6px', 
                width:'100%', 
                opacity:'0.8', 
                backgroundColor:'#222546', 
                bottom:0, 
                display:'flex', 
                justifyContent: 'center'
            }
        },
        '.resultOpen':{
            backgroundColor:theme.palette.paleBlue
        },
        '.detailsTitle':{
            fontSize:21,
            color:theme.palette.primary.main,
            '& .detailPrice':{
                fontWeight:'bold'
            },
            '& .priceOverText':{
                fontWeight:'bold',
                fontStyle:'italic'
            },
            paddingBottom:20,
            borderBottom:'1px solid #e0d0e0',
            marginBottom:20,
            [theme.breakpoints.down("sm")]: {
                fontSize: 18,
                lineHeight: 1.5,
                padding:0
            },
        },
        '.propertyListDetails':{
            fontSize:'16px',
            lineHeight:'32px'
        },
        '.propertyListDetailsBold':{
            fontSize:'16px',
            fontWeight:'bold'
        },
        '.propertyBadge':{
            width:25,
            height:25,
            backgroundColor:theme.palette.primary.main,
            color:'#FFFFFF',
            '& .innerIcon':{
                fontSize:10
            }
        },
        '.iconContainer':{
            marginBottom:'10px'
        },
        'bluePropertyAttribute':{
            fontSize:14,
            textTransform:'uppercase',
            color:theme.palette.primary.main,
            verticalAlign:'middle',
            lineHeight:'25px',
            paddingLeft:'0',
            marginLeft:-8,
            [theme.breakpoints.down("sm")]: {
                lineHeight:'18px',
            }
    
        },
        '& .formFooterBox':{
            backgroundColor:theme.palette.brandedPrimary,
            paddingLeft:'60px',
            paddingright:'60px',
            paddingTop:'40px',
            paddingBottom:'40px',
            marginTop:'60px',
            marginLeft:'-60px',
            marginBottom:'-60px',
            marginRight:'-60px',
        },
        menuIcon: {
            marginRight: theme.spacing(2),
        },
        list:     {
            width: '200px',
        },
        link:     {
            textDecoration: 'none',
            color:'#757575'
        },
        
        normalButton: {
            textTransform: 'none',
            fontSize:'18px',
            fontFamily:"Cabin",
            fontWeight:'bold',
            textDecoration:'underline',
            [theme.breakpoints.down("sm")]: {
                fontSize:16,
                marginRight:10,
                paddingLeft: 10,
            },
            [theme.breakpoints.down("xs")]: {
                fontSize:14,
                marginRight:5,
                paddingLeft: 5,
            }
        },
        bigNavBar:{
            paddingTop:'15px',
            paddingBottom:'15px',
    
        },
        
        '.whiteText':{
            color:theme.palette.white
        },

        '.formUpdated':{
            opacity:0.7
        },

        '.expanderBox':{
            padding:'15px', 
            marginTop:'20px',
            '& .expanderBoxHeader':{
                display:'flex',
                '& .headerTitleText':{
                    flexGrow:1,
                    marginTop:'10px',
                    cursor:'pointer'
                },
                '& .toggleButtonContainer':{
                    fontWeight:600,
                    fontFamily:FONT_PRIMARY,
                    fontSize:'21px',
                    lineHeight:1,
                    letterSpacing:0,
                    color:PALETTE.brandedPrimary
                }
            },
            '.expanderBoxContent':{
                paddingTop:'20px',
                paddingBottom:'20px'
            }
        },
        '.linkCell':{
            cursor:'pointer'
        },
        '.msgDrop':{
            marginTop:'20px',
            marginBottom:'20px',
            padding:'20px',
            textAlign:'center',
            backgroundColor:'#E7E8E8',
            border:'0.5px dashed #707070',
            minWidth:'300px',
            fontWeight:600,
            fontSize:'12px'
        },
        '.inboxMessageContainer':{
            borderBottom:'1px solid #d9d9d9'
        },
        '.checklistItemsContainer':{
            '& .checklistItem':{
                display:'flex', 
                borderBottom:'1px solid #d9d9d9',
                borderLeftWidth:'5px',
                borderLeftStyle:'solid',
                borderRightWidth:'5px',
                borderRightStyle:'solid',
                paddingLeft:'10px',
                paddingRight:'10px',
                '& .itemTitle':{
                    flexGrow:1, 
                    paddingTop:'10px',
                    cursor:'pointer'
                }
            },
            '& .taskOverDue':{
                borderLeftColor:'#FF0000',
                borderRightColor:'#FF0000',
            },
            '& .taskDue':{
                borderLeftColor:'#FF8C00',
                borderRightColor:'#FF8C00',
            },
            '& .taskNotYetDue':{
                borderLeftColor:'#F0F0F1',
                borderRightColor:'#F0F0F1',
            },
            '& .taskComplete':{
                borderLeftColor:theme.palette.complete,
                borderRightColor:theme.palette.complete,
            },
            '& .taskNa':{
                borderLeftColor:'#F0F0F1',
                borderRightColor:'#F0F0F1',
	            opacity:0.5
            }
        },
        '.taskItemsContainer':{
            paddingTop:'10px',
            '& .taskIcon':{
                fontSize:'30px',
                marginTop:'10px',
                marginRight:'10px'
            },
            '& .taskComplete':{
                '& .taskIcon':{
                    color:theme.palette.complete
                }
            },
            '& .taskItem':{
                display:'flex', 
                borderBottom:'1px solid #d9d9d9',
                paddingLeft:'10px',
                paddingRight:'10px',
                '& .itemContents':{
                    paddingTop:'10px',
                    paddingBottom:'5px',
                },
                '& .overDue':{
                    color:'#FF0000'
                },
                '& .itemTitle':{
                    flexGrow:1, 
                    color:theme.palette.brandedPrimary,
                    fontWeight:600
                },
                '& .itemClick':{
                    cursor:'pointer',
                }
            },
        },
        '.budgetPlannerContainer':{
            '& .complexCell':{
                '& .MuiTableCell-root':{
                    padding:'0 10px 0 10px'
                },
                '& .buttonsBox':{
                    marginTop:'15px',
                    display:'flex',
                    flexDirection: 'row-reverse'   
                },
                '& .budgetPlannerLabel':{
                    
                },
                '& .budgetPlannerValue':{
                    fontWeight:800,
                    color:theme.palette.brandedPrimary
                }
            }
        },
        '.rightAlignInput':{
            '& .MuiInputBase-input':{
                textAlign: 'right'
            }
        },
        '.relatedMattersContainer':{
            cursor:'pointer',
            marginBottom:'16px',
            '& .resultsTools':{
                paddingTop: '8px',
                paddingBottom: '8px',
                '& .removeLink':{
                    cursor: 'pointer',
                    fontSize:'10px',
                    fontWeight:'bold',
                    textTransform: 'uppercase',
                    textDecoration: 'underline',
                    textAlign: 'center',
                    color:theme.palette.brandedPrimary,
                    '&:hover': {
                        textDecoration: 'underline',
                    },
                }
            },
            '& .relatedMattersInnerTitle':{
                color: theme.palette.brandedPrimary,
                fontWeight:'bold',
                fontSize:'16px',
                textAlign: 'center',
                paddingTop: '8px',
                paddingBottom: '8px',
            },
            '& .relatedMattersInnerContainer':{
                paddingLeft:'20px',
                paddingRight:'20px',
                paddingBottom:'20px',
                '& .rTransStandard':{
                    fontSize:'12px',
                    textAlign:'center',
                    '& .boldLabel':{
                        fontWeight: 'bold',
                    },
                    '& .blueLabel':{
                        color:theme.palette.boldBlue
                    }
                }
            }
        },
        '.headerSearchInput':{
            color: 'inherit',
            width: '100%',
            '& .MuiInputBase-input': {
                padding: theme.spacing(1, 1, 1, 0),
                // vertical padding + font size from searchIcon
                paddingLeft: `calc(1em + ${theme.spacing(4)})`,
                transition: theme.transitions.create('width'),
                [theme.breakpoints.up('sm')]: {
                    width: '20ch',
                    '&:focus': {
                    width: '20ch',
                    },
                },
            },
        }
    },

}))(() => null);
