import React, { createContext } from 'react';

export const FileBlockContext = createContext();

class FileBlockContextProvider extends React.Component {
    constructor(props){
        super(props);

        const {
            doc={},
            dType={},
            matterId,
            isArchive=false,
            sendToIcon=false,
            sendToSortingOffice=false
        } = props;

        const obj = {
            isDragging:false,
            isLoaded:false,
            doc,
            docs:[],
            dType,
            isArchive,
            sendToIcon,
            sendToSortingOffice,
            matterId
        };
        this.state = obj;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.doc !== this.props.doc) {
            this.setState({ doc: this.props.doc });
        }
    }

    setIsLoaded(){
        this.setState({isLoaded:true});
    }

    reload(){
        this.setState({isLoaded:false});
    }

    render(){
        return (
            <FileBlockContext.Provider value={{
                ...this.state,
                setState: this.setState.bind(this),
                setIsLoaded: this.setIsLoaded.bind(this),
                reload:this.reload.bind(this)
            }}>
                {this.props.children}
            </FileBlockContext.Provider>
        );
    }

}
export default FileBlockContextProvider;