import React, { useContext, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import axios from '../../../utils/axios';
import { cLog, cWarn } from '../../CustomLogger';
import { getComponentsFromUtcDate, getValidationFromFields, initialValues } from '../../UniversalComponents';
import FileThumbBlock from './FileThumbBlock';
import { BrandContext } from '../../../contexts/BrandContext';

import MicrosoftIcon from '@mui/icons-material/Microsoft';
import { CloudDownload, Delete, Description, FileCopy, PictureAsPdf, Public } from '@mui/icons-material';
import { format } from 'date-fns';
import { Formik } from 'formik';
import BasicInputField from '../../form/BasicInputField';
import { FileBlockContext } from './FileBlockContext';
import { DragContext } from '../../Widgets/DragContext';
import RecyclingIcon from '@mui/icons-material/Recycling';
import SendToDocCatDialogue from './SendToDocCatDialogue';
import { WidgetContext } from '../../Widgets/WidgetContext';
import { WidgetsContext } from '../../pages/pcdashboard/WidgetsContext';

export default function FileDialogue(props){

    const context = useContext(FileBlockContext);
    
    const dragContext = useContext(DragContext);

    const {
        notifyDrop
    } = dragContext;

    const {
        doc:{
            docName='',
            id:docId=0,
            dateCreated,
            bucket:{
                mimeType=''
            }
        },
        doc,
        isArchive=false,
        sendToIcon=false,
        sendToSortingOffice=false,
    } = context;
    
    const {
        showDialogue,
        setShowDialogue,
    } = props;
    
    const brandContext = useContext(BrandContext);

    const {
        textContent:{
            downloadCopyButton='',
            viewPdfVersionButton='',
            openWordOnlineButton='',
            openInWordButton='',
            createdOn='',
            archiveDocButton='',
            save='',
            deArchiveDocButton='',
            sendToSortingOfficeButton='',
            sendToIconButton=''
        }
    } = brandContext;
   
    const downloadFile = (path) => {
        const apiPath = `${process.env.REACT_APP_API_URL}/${path}/${docId}`;

        axios.get(apiPath,{
            timeout: 300000,
            withCredentials:true,
            responseType: "arraybuffer"
        }).then(response => {
            const blob = new Blob([response.data]);
            const link = document.createElement('a')
            const contentDisposition = response.headers['content-disposition'];    
            const filenameMatch = contentDisposition.match(/filename="(.+)"/);
            const filename = filenameMatch ? filenameMatch[1] : 'file.docx'; 
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            link.click()

        }).catch(err => {
            console.log('caught the error');
            console.log(err);
        });
    }

    const openRedirect = (path) => {
        const apiPath = `${process.env.REACT_APP_API_URL}/${path}/${docId}`;
        axios.get(apiPath,{
            timeout: 300000,
            withCredentials:true,
        }).then(res => {
            const{
                data:{
                    redirect=''
                }
            } = res;
            if(redirect){
                window.open(redirect);
            }
            
            
        }).catch(err => {
            cWarn(err);
        });
    }

    const archiveDoc = () => {
        //we have a drop on a document category, update it
        const apiPath = `${process.env.REACT_APP_API_URL}/docarchive`;
        // Make a POST request to your API using Axios
        axios.post(apiPath, {
            docId,
        })
        .then((res) => {
            notifyDrop();
            setShowDialogue(false)
        })
        .catch((error) => {
            cWarn(error);
        });
    }

    const dearchiveDoc = () => {
        //we have a drop on a document category, update it
        const apiPath = `${process.env.REACT_APP_API_URL}/dearchivecod`;
        // Make a POST request to your API using Axios
        axios.post(apiPath, {
            docId,
        })
        .then((res) => {
            notifyDrop();
            setShowDialogue(false)
        })
        .catch((error) => {
            cWarn(error);
        });
    }
    
    const{
        year,
        month,
        day,
        hours,
        mins

    } = getComponentsFromUtcDate(dateCreated);

    

    const date = new Date(year, month - 1, day); // Month is 0-based, so subtract 1

    // Format the date using date-fns
    const dateCreatedLabel = format(date, 'do MMM yyyy', { useAdditionalWeekYearTokens: true });
    const dateCreatedTimeLabel = `${hours}:${mins}`;

    const formFields = {
        'docName':{
            'label':'renameDocumentLabel'
        }
    };
    
    const sendToSortingOfficeMethod = () => {
        const apiPath = `${process.env.REACT_APP_API_URL}/doccatupdate`;
        // Make a POST request to your API using Axios
        axios.post(apiPath, {
            docId,
            docCatId:73
        })
        .then(() => {
            // Handle the response from your API, e.g., store the uploaded files in state
            setShowDialogue(false);
        })
        .catch((error) => {
            // Handle errors, e.g., display an error message
            cWarn(error);
        });
    }
    const [showSendToCatDialog,setShowSendToCatDialog] = useState(false);

    const widgetContext = useContext(WidgetContext);
    const widgetsContext = useContext(WidgetsContext);
    const isWordDoc = mimeType === 'application/msword';

    return (
        <>
        <Dialog open={showDialogue} onClose={()=>{setShowDialogue(false)}}>
            <DialogTitle>{docName}</DialogTitle>
            <DialogContent>
                <FileThumbBlock doc={doc} />
                <Typography>{createdOn} {dateCreatedLabel} {dateCreatedTimeLabel}</Typography>
                {!isArchive &&
                <Formik
                    initialValues={initialValues(formFields)}
                    validationSchema={getValidationFromFields(formFields)}
                    onSubmit={(values)=>{
                        const apiPath = `${process.env.REACT_APP_API_URL}/renamedoc/${docId}`;
                        axios.post(apiPath,values,{
                            timeout: 300000,
                            withCredentials:true
                        }).then((res) => {
                            context.doc.docName = values.docName;
                            context.setState({});
                            widgetsContext.nudge('filesUpdate');
                            if(widgetContext){
                                widgetContext.reload();
                            }
                           cLog(res.data)         
                        }).catch(err => {
                            cWarn(err); 
                        });
                    }}
                >
                    {props => (
                        <>
                            <form onSubmit={props.handleSubmit}>
                                <Grid container >
                                    <Grid item xs={12} md={12} className="inputHolder">
                                        <BasicInputField autoComplete='off' autoFocus={false} className="cardInput" formik={props} formFields={formFields} fieldName="docName" variant="outlined" sx={{width:400}}/>  
                                    </Grid>
                                </Grid>
                            </form>     
                            <Button variant="outlined" onClick={props.handleSubmit}>{save}</Button>
                        </>
                    )}
                </Formik>
                }
            </DialogContent>
            <DialogActions>
                {isArchive ? 
                    <Tooltip title={deArchiveDocButton}>
                        <IconButton onClick={()=>{dearchiveDoc()}}><RecyclingIcon/></IconButton>   
                    </Tooltip>
                :
                    <>                       
                        {sendToSortingOffice &&
                            <Tooltip title={sendToSortingOfficeButton}>
                                <IconButton onClick={sendToSortingOfficeMethod}><FileCopy/></IconButton>
                            </Tooltip>

                        }
                        {sendToIcon &&
                            <Tooltip title={sendToIconButton}>
                                <IconButton onClick={()=>{setShowSendToCatDialog(true)}}><Description/></IconButton>
                            </Tooltip>

                        }
                        
                        {isWordDoc &&
                            <>
                                <Tooltip title={openInWordButton}>
                                    <IconButton onClick={()=>{openRedirect('doc/365open')}}><MicrosoftIcon/></IconButton>
                                </Tooltip>
                                <Tooltip title={openWordOnlineButton}>
                                    <IconButton onClick={()=>{openRedirect('doc/365online')}}><Public/></IconButton>
                                </Tooltip>
                                <Tooltip title={viewPdfVersionButton}>
                                    <IconButton onClick={()=>{downloadFile('doc/pdf')}}><PictureAsPdf/></IconButton>
                                </Tooltip>
                            </>
                        }
                        <Tooltip title={downloadCopyButton}>
                            <IconButton onClick={()=>{downloadFile('doc')}}><CloudDownload/></IconButton>   
                        </Tooltip>
                        <Tooltip title={archiveDocButton}>
                            <IconButton onClick={()=>{archiveDoc()}}><Delete/></IconButton>   
                        </Tooltip>
                    </>
                }
            </DialogActions>
        </Dialog>
        {showSendToCatDialog &&
            <SendToDocCatDialogue  showDialogue={showSendToCatDialog} setShowDialogue={setShowSendToCatDialog}/>
        }
        </>
    );

}