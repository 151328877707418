import React, { useContext, useEffect, useState } from "react";
import { BrandContext } from "../../contexts/BrandContext";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Popper } from "@mui/material";
import { Formik } from 'formik';
import axios from '../../utils/axios';
import BasicInputField from "../form/BasicInputField";
import { cWarn } from "../CustomLogger";
import { getValidationFromFields, initialValues } from "../UniversalComponents";
import { styled } from "@mui/system";

export default function AddRelatedTransactionDialogue(props){


    const {
        showDialogue,
        setShowDialogue,  
        context:{
            loadData,
            matterId,
            details:{
                events=[],
            },
            userRoles=[]
        },     
    } = props;

    const {
        textContent:{
            addRelatedTransaction:title='',
            cancel='',
            save=''
        }
    } = useContext(BrandContext);

    const formFields = {
        matter:{
            type:'typeahead',
            apiLookupPath:'/crossdeptsearch',
            runPost:true,
            optionTemplate: (option) => {
                if (typeof option === 'string') {
                    return option;
                }
                return `${option.label}`
            },
            extendProps:{
                PopperComponent:(props)=><StyledPopper {...props}></StyledPopper>
            },
            required:false
        }
    }

    const StyledPopper = styled(Popper)({
        zIndex: 1300, // Ensure it appears above other components
      });
    return  (
        <Dialog open={showDialogue} onClose={()=>{setShowDialogue(false)}} fullWidth className="brandedDialogue dialogeWithAutoType">
            <DialogTitle>
                {title}
            </DialogTitle>
            <Formik
                initialValues={initialValues(formFields)}
                validationSchema={getValidationFromFields(formFields)}
                onSubmit={(values)=>{
                    const {
                        matter:{
                            value:relatedMatter
                        }
                    } = values;
                    const apiPath = `${process.env.REACT_APP_API_URL}/addrelatedmatter/${matterId}`;
                    axios.post(apiPath,{
                        matter:relatedMatter,
                    },{
                        timeout: 300000,
                        withCredentials:true
                    }).then((res) => {
                        const {
                            data:{
                                success=false
                            }
                        } = res;
                        if(success){
                            setShowDialogue(false)
                            loadData();
                        }
                        else{
                            cWarn('There was a problem saving the new valuation');
                        }
                        
                    }).catch(err => {
                        cWarn(err); 
                    });
                }}
            >
                {props => (
                    <>
                        <DialogContent>
                            <form onSubmit={props.handleSubmit}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12} className="inputHolder">
                                        <BasicInputField className="cardInput" formik={props} formFields={formFields} fieldName={`matter`} variant="outlined" sx={{width:'100%'}}/>  
                                    </Grid>
                                </Grid>
                            </form>     
                        </DialogContent>
                        <DialogActions>
                        <Button variant="outlined" onClick={()=>{setShowDialogue(false)}}>{cancel}</Button>
                            <Button variant="outlined" onClick={props.handleSubmit}>{save}</Button>
                        </DialogActions>
                    </>
                )}
            </Formik>
        </Dialog>
    );


}
